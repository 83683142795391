import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc, serverTimestamp, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TailSpin } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const CityCollection = () => {
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [newCity, setNewCity] = useState("");
    const [addingCity, setAddingCity] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [editCityId, setEditCityId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const citySnapshot = await getDocs(collection(db, "cityCollection"));
                const cityData = citySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCities(cityData);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error loading data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleAddCity = async () => {
        if (!newCity.trim()) {
            toast.error("City name cannot be empty");
            return;
        }

        try {
            setAddingCity(true);
            if (editCityId) {
                await updateDoc(doc(db, "cityCollection", editCityId), { name: newCity });
                setCities((prev) =>
                    prev.map((city) => (city.id === editCityId ? { ...city, name: newCity } : city))
                );
                toast.success("City updated successfully");
                setEditCityId(null);
            } else {
                const docRef = await addDoc(collection(db, "cityCollection"), {
                    name: newCity,
                    createdAt: serverTimestamp(),
                });
                setCities([{ id: docRef.id, name: newCity, createdAt: new Date() }, ...cities]);
                toast.success("City added successfully");
            }
            setNewCity("");
            setShowPopup(false);
        } catch (error) {
            console.error("Error adding/updating city:", error);
            toast.error("Failed to add/update city");
        } finally {
            setAddingCity(false);
        }
    };

    const handleEditCity = (cityId, cityName) => {
        setEditCityId(cityId);
        setNewCity(cityName);
        setShowPopup(true);
    };

    const handleDeleteCity = async (cityId) => {
        if (window.confirm("Are you sure you want to delete this city?")) {
            try {
                await deleteDoc(doc(db, "cityCollection", cityId));
                setCities((prev) => prev.filter((city) => city.id !== cityId));
                toast.success("City deleted successfully");
            } catch (error) {
                console.error("Error deleting city:", error);
                toast.error("Failed to delete city");
            }
        }
    };

    const filteredCities = cities.filter((city) =>
        city.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-lg font-bold sm:text-2xl font-krona">Cities</h1>
                <div className="flex items-center gap-4">
                <button
                        className="px-4 py-2 ml-4 rounded-md text-headings hover:text-white bg-subHeading hover:bg-headings"
                        onClick={() => setShowPopup(true)}
                    >
                        Create City
                    </button>
                    <input
                        type="text"
                        placeholder="Search by city name"
                        className="py-2 pl-10 pr-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                   
                </div>
            </div>

            {/* Modal Popup for Adding/Editing City */}
            {showPopup && (
                <div className="fixed inset-0 z-40 flex items-center justify-center bg-gray-500 bg-opacity-75">
                    <div className="p-6 bg-white rounded-md shadow-lg w-[400px] relative">
                        <h2 className="mb-4 text-xl font-bold">{editCityId ? "Edit City" : "Add New City"}</h2>
                        <input
                            type="text"
                            placeholder="Enter city name"
                            className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-headings"
                            value={newCity}
                            onChange={(e) => setNewCity(e.target.value)}
                        />
                        <button
                            onClick={handleAddCity}
                            disabled={addingCity}
                            className={`w-full py-2 px-4 text-white rounded-md ${addingCity ? "bg-gray-400" : "bg-headings"}`}
                        >
                            {addingCity ? "Saving..." : editCityId ? "Update City" : "Add City"}
                        </button>
                        <button
                            className="absolute px-3 py-1 text-gray-600 rounded-full bg-subHeading top-2 right-2 hover:bg-gray-100"
                            onClick={() => { setShowPopup(false); setEditCityId(null); setNewCity(""); }}
                        >
                            ✕
                        </button>
                    </div>
                </div>
            )}

            {loading ? (
                <div className="flex items-center justify-center h-64">
                    <TailSpin height="80" width="80" color="blue" ariaLabel="loading" visible={true} />
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full rounded-lg shadow-lg table-auto">
                        <thead>
                            <tr className="text-lg text-left text-custom-gray">
                                <td className="px-4 py-2">ID</td>
                                <td className="px-4 py-2">Name</td>
                                <td className="px-4 py-2">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {filteredCities
                                .sort((a, b) => b.createdAt - a.createdAt)
                                .map((city) => (
                                    <tr key={city.id} className="border-b-8 border-[#F8F8F8] py-2">
                                        <td className="px-4 py-5 font-semibold uppercase">#{city.id.slice(0,8)}</td>
                                        <td className="px-4 py-5">{city.name || "N/A"}</td>


              
                                        <td className="flex gap-2 px-4 py-5">
                                            <button
                                                onClick={() => handleEditCity(city.id, city.name)}
                                                className="px-3 py-1 text-white rounded-md bg-subHeading"
                                            >
                                                <i className='fas fa-edit'></i>
                                                {/* Edit */}
                                            </button>
                                            <button
                                                onClick={() => handleDeleteCity(city.id)}
                                                className="px-3 py-1 text-white rounded-md bg-subHeading"
                                            >
                                               <i className="text-red-500 fas fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            )}
            <ToastContainer />
        </>
    );
};
