import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Assuming you have already configured firebase
import { TailSpin } from 'react-loader-spinner'; // Importing the loader

export const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([
    { label: 'Total Advisors', value: 0 },
    { label: 'Total SuperAdvisors', value: 0 },
    { label: 'Total Posts', value: 0 },
    { label: 'Total Coupons', value: 0 },
    { label: 'Total Bookings', value: 0 },
    { label: 'Total Notifications', value: 0 },
    { label: 'Total Ideas', value: 0 },
    { label: 'Total Packages', value: 0 },
  ]);
  const [loading, setLoading] = useState(true); // Set loading state

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true); // Set loading to true while fetching
      try {
     
                const advisorsSnapshot = await getDocs(collection(db, 'userCollection'));
        const totalUsers = advisorsSnapshot.size;


        const superAdvisorsSnapshot = await getDocs(collection(db, 'riderCollection'));
        const totalRiders = superAdvisorsSnapshot.docs.length;

        const postsSnapshot = await getDocs(collection(db, 'rideCollection'));
        const totalRides = postsSnapshot.size;


        const couponsSnapshot = await getDocs(collection(db, 'vehicleCollection'));
        const totalTypes = couponsSnapshot.size;


        const bookingsSnapshot = await getDocs(collection(db, 'cityCollection'));
        const totalCities = bookingsSnapshot.size;

        
    
        const ideasSnapshot = await getDocs(collection(db, 'auctionCollection'));
        const totalRevenue = ideasSnapshot.size;


        // const notificationsSnapshot = await getDocs(collection(db, 'blogsCollection'));
        // const totalBlogs = notificationsSnapshot.size;

        setDashboardData([
          { label: 'Total Users', value: totalUsers },
          { label: 'Total Riders', value: totalRiders },
          { label: 'Total Rides', value: totalRides },
          { label: 'Vehicle Types', value: totalTypes },
          { label: 'Total Cities', value: totalCities },
          { label: 'Total Revenue', value:  totalRevenue},
          
          // { label: 'Total Blogs', value: totalBlogs },
          // { label: 'Total Packages', value: totalPackages },
        ]);
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchStats(); // Call the function on component mount
  }, []);

  return (
    <>
      <h1 className="pb-6 text-3xl font-bold font-krona ">Dashboard</h1>
      {loading ? (
        // Loader while fetching data
        <div className="flex items-center justify-center h-64">
          <TailSpin
            height="80"
            width="80"
            color="blue"
            ariaLabel="loading"
          />
        </div>
      ) : (
        // Display data once loaded
        <div className="grid grid-cols-1 gap-6 pr-2 md:grid-cols-2 lg:grid-cols-4">
          {dashboardData.map((items, index) => (
            // shadow-lg transform transition ease-linear duration-[1000] hover:-translate-y-2
            <div key={index} className="p-6 lg:h-[130px] h-full w-full lg:max-w-[340px] bg-white rounded-lg ">
              <h3 className="text-md ">{items.label}</h3>
              <p className="py-4 text-2xl font-bold ">{items.value}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

// export default Stats;
