import React, { useState } from 'react';
// import profile from '../imges/logo copy.png'
import { useNavigate } from 'react-router-dom';
import profile from '../imges/okab_logo.png'
import { signOut } from 'firebase/auth'; 
import { auth } from '../firebase';
import { Dashboard } from './Dashboard';
import { Users } from './Users';
import { Riders } from './Riders';
import { Rides } from './Rides';
import { Vehicles } from './Vehicles';
import { ContactUs } from './ContactUs';
import { CityCollection } from './CityCollection';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsConditions } from './TermsConditions';
// import { Buyers } from './Buyers';
// import { Sellers } from './Sellers';
// import { Orders } from './Orders';
// import { Auctions } from './Auctions';
// import { Categories } from './Categories';
// import { Sub_Categories } from './Sub_Categories';
// import { FAQs } from './FAQs';
// import { TermsConditions } from './TermsConditions';
// import { PrivacyPolicy } from './PrivacyPolicy';
// import { Blogs } from './Blogs';

export const AdminDashboard = () => {
  const [activeComponent, setActiveComponent] = useState('dashboard');

    const navigate = useNavigate();
  
    const handleLogout = async () => {
      try {
        await signOut(auth);
        console.log('User logged out');
        navigate('/'); 
      } catch (error) {
        console.error('Logout error:', error);
      }}
  
  const menuItems = [
    { name: 'dashboard', label: 'Dashboard', icon:'fas fa-tachometer-alt' },
    { name: 'users', label: 'Users',  icon: 'fas fa-users' },
    // fa-person-biking
    { name: 'riders', label: 'Riders', icon: 'fas fa-motorcycle' },
    { name: 'vehicles', label: 'Vehicles', icon: 'fas fa-car ' },
    { name: 'rides', label: 'Rides', icon: 'fas fa-person-biking' },
    { name: 'contactus', label: 'Queries', icon:  'fas fa-address-book' },
    { name: 'cityCollection', label: 'Cities', icon:'fas fa-city' },
    // { name: 'faqs', label: 'FAQs', icon:'fas fa-question-circle' },
    // { name: 'blogs', label: 'Blogs', icon:'fas fa-blog' },
    { name: 'privacyPolicy', label: 'Privacy Policy', icon:'fas fa-user-secret' },
    { name: 'termsConditions', label: 'Terms & Conditions', icon:'fas fa-file-contract' },
  ];
  

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50">
    {/* Fixed Sidebar */}
    <div className="flex flex-col w-64 h-screen bg-white border-r border-gray-200">
      {/* Fixed Header */}
      <div className="flex-shrink-0">
        <div className="p-5 py-[10px]">
          <div className="text-2xl font-bold text-gray-800 font-krona">
            
                <img src={profile} className="w-[30%] h-14 " alt="Profile" />
          </div>
        </div>
        <div className="h-px bg-gray-200" />
      </div>

      {/* Scrollable Navigation */}
      <div className="flex flex-col flex-1 overflow-hidden">
        <nav className="flex-1 overflow-y-auto">
          <ul className="py-5 space-y-2">
            {menuItems.map((item) => (
              <li
                key={item.name}
                className={`flex items-center px-5 py-3 cursor-pointer space-x-3 transition-colors duration-150
                  ${
                    activeComponent === item.name
                      ? 'bg-blue-50 border-l-4 border-headings text-xl text-headings'
                      : 'text-gray-600 hover:bg-gray-50 border-l-4 border-transparent'
                  }`}
                onClick={() => setActiveComponent(item.name)}
              >
                <i className={`${item.icon} w-5`} />
                <span className="font-medium text-md">{item.label}</span>
              </li>
            ))}
          </ul>
        </nav>

        {/* Fixed Logout Button */}
        <div className="flex-shrink-0 border-t border-gray-200">
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-5 py-4 text-sm font-medium transition-colors duration-150 text-custom-gray hover:bg-gray-50 hover:text-headings"
          >
            <i className="mr-3 fas fa-sign-out-alt" />
            Logout
          </button>
        </div>
      </div>
    </div>
    <div className="flex flex-col flex-1 overflow-hidden text-start">
      {/* Fixed Header */}
      <header className="flex-shrink-0 px-6 py-3 bg-white border-b border-gray-200">
        <h1 className="text-xl font-bold text-gray-800 font-krona">Welcome Admin!</h1>
        <p className="mt-1 text-sm text-gray-600">
          {new Date().toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </p>
      </header>

      {/* Main Content Area */}
      <main className="flex-1 p-4 overflow-auto">
        <div className="">
          {activeComponent === 'dashboard' && (
          <Dashboard/>
  
          )}
          {activeComponent === 'users' && <Users/>}
          {activeComponent === 'riders' && <Riders />}
          {activeComponent === 'vehicles' &&  <Vehicles />}
          {activeComponent === 'rides' && <Rides />}
          {activeComponent === 'contactus' && <ContactUs />}
          {activeComponent === 'cityCollection' && <CityCollection />}
          {/* {activeComponent === 'faqs' && <FAQs />} */}
          {/* {activeComponent === 'blogs' && <Blogs/>} */}
          {activeComponent === 'privacyPolicy' &&  <PrivacyPolicy />}
          {activeComponent === 'termsConditions' && <TermsConditions />}
        </div>
      </main>
    </div>
  </div>
  
  );
};
  