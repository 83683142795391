import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { TailSpin } from 'react-loader-spinner'; // Spinner for loading state
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import dumyimage from '../imges/dummy.png'

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase'; 
export const Vehicles = () => {
  const [vehicles, SetVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showvehiclesForm, setShowvehiclesForm] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [index, setindex] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCouponId, setSelectedCouponId] = useState(null); // For editing
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showActions, setShowActions] = useState(null); // Controls which dropdown is shown
  const [selectedUser, setSelectedUser] = useState(null); // State to track selected user
  const [icon, setVehicleIcon] = useState(null);
  const [image, setVehicleImage] = useState(null);
  // Fetch vehicles from Firestore
  useEffect(() => {
    const fetchvehicles = async () => {
      setLoading(true); // Show loading spinner while fetching data
      try {
        const querySnapshot = await getDocs(collection(db, 'vehicleCollection'));
        const vehiclesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // date: doc.data().date.toDate(), 
        }));
        SetVehicles(vehiclesData); // Store the fetched vehicles in state
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      } finally {
        setLoading(false); // Hide spinner after fetching is done
      }
    };

    fetchvehicles();
  }, []);


  // const handleAddCoupon = async () => {
  //   if (!name && !selectedCouponId) {
  //     toast.error('Name Cannot be Empty.....', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       theme: 'colored',
  //     });
  //     return;
  //   } else if (!price && !selectedCouponId) {
  //     toast.error('Price Cannot be Empty.....', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       theme: 'colored',
  //     });
  //     return;
  //   } else if (!index && !selectedCouponId) {
  //     toast.error('Index Cannot be Empty.....', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       theme: 'colored',
  //     });
  //     return;
  //   } else if (!icon && !selectedCouponId && !vehicles.find(v => v.id === selectedCouponId)?.icon) {
  //     toast.error('Please Upload Vehicle Icon', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       theme: 'colored',
  //     });
  //     return;
  //   } else if (!image && !selectedCouponId && !vehicles.find(v => v.id === selectedCouponId)?.image) {
  //     toast.error('Please Upload Vehicle Image', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       theme: 'colored',
  //     });
  //     return;
  //   }
  
  //   try {
  //     const updatedCouponData = {
  //       vehicleName: name,
  //       perKmPrice: price,
  //       index: parseFloat(index)
  //     };
  
  //     if (selectedCouponId) {
  //       const existingVehicle = vehicles.find(v => v.id === selectedCouponId);
  //       const couponRef = doc(db, 'vehicleCollection', selectedCouponId);
        
  //       // Only update icon if a new one is provided
  //       if (icon && typeof icon !== 'string') {
  //         const iconRef = ref(storage, `vehicleIcons/${icon.name}`);
  //         const iconSnapshot = await uploadBytes(iconRef, icon);
  //         updatedCouponData.icon = await getDownloadURL(iconSnapshot.ref);
  //       } else {
  //         // Keep the existing icon URL
  //         updatedCouponData.icon = existingVehicle.icon;
  //       }
  
  //       // Only update image if a new one is provided
  //       if (image && typeof image !== 'string') {
  //         const imageRef = ref(storage, `vehicleImages/${image.name}`);
  //         const imageSnapshot = await uploadBytes(imageRef, image);
  //         updatedCouponData.image = await getDownloadURL(imageSnapshot.ref);
  //       } else {
  //         // Keep the existing image URL
  //         updatedCouponData.image = existingVehicle.image;
  //       }
  
  //       await updateDoc(couponRef, updatedCouponData);
        
  //       SetVehicles(prevVehicles =>
  //         prevVehicles.map(vehicle =>
  //           vehicle.id === selectedCouponId ? { ...vehicle, ...updatedCouponData } : vehicle
  //         )
  //       );
  
  //       toast.success('Vehicle Updated Successfully!', {
  //         position: 'top-right',
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         theme: 'colored',
  //       });
  //     } else {
  //       // Handle new vehicle creation
  //       const iconRef = ref(storage, `vehicleIcons/${icon.name}`);
  //       const imageRef = ref(storage, `vehicleImages/${image.name}`);
  
  //       const [iconSnapshot, imageSnapshot] = await Promise.all([
  //         uploadBytes(iconRef, icon),
  //         uploadBytes(imageRef, image),
  //       ]);
  
  //       const [iconUrl, imageUrl] = await Promise.all([
  //         getDownloadURL(iconSnapshot.ref),
  //         getDownloadURL(imageSnapshot.ref),
  //       ]);
  
  //       const newVehicle = {
  //         // id: docRef.id,
  //         ...updatedCouponData,
  //         icon: iconUrl,
  //         image: imageUrl,
  //       };
  
  //       const vehiclesRef = collection(db, 'vehicleCollection');
  //       const docRef = await addDoc(vehiclesRef, newVehicle);
  //       SetVehicles([{ id: docRef.id, ...newVehicle }, ...vehicles]);
  
  //       toast.success('Vehicle Added Successfully!', {
  //         position: 'top-right',
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         theme: 'colored',
  //       });
  //     }
  
  //     // Reset form and state
  //     setName('');
  //     setPrice('');
  //     setindex('');
  //     setVehicleIcon(null);
  //     setVehicleImage(null);
  //     setShowvehiclesForm(false);
  //     setSelectedCouponId(null);
  //   } catch (error) {
  //     console.error('Error adding or updating Vehicle:', error);
  //     toast.error('Failed to save vehicle. Please try again.', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       theme: 'colored',
  //     });
  //   }
  // };
   

  // Function to set the selected coupon for editing
  
  const handleAddCoupon = async () => {
    if (!name && !selectedCouponId) {
      toast.error('Name Cannot be Empty.....', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    } else if (!price && !selectedCouponId) {
      toast.error('Price Cannot be Empty.....', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    } else if (!index && !selectedCouponId) {
      toast.error('Index Cannot be Empty.....', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    } else if (!icon && !selectedCouponId && !vehicles.find(v => v.id === selectedCouponId)?.icon) {
      toast.error('Please Upload Vehicle Icon', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    } else if (!image && !selectedCouponId && !vehicles.find(v => v.id === selectedCouponId)?.image) {
      toast.error('Please Upload Vehicle Image', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    }
  
    try {
      const updatedCouponData = {
        vehicleName: name,
        perKmPrice: price,
        index: parseFloat(index)
      };
  
      if (selectedCouponId) {
        const existingVehicle = vehicles.find(v => v.id === selectedCouponId);
        const couponRef = doc(db, 'vehicleCollection', selectedCouponId);
  
        // Only update icon if a new one is provided
        if (icon && typeof icon !== 'string') {
          const iconRef = ref(storage, `vehicleIcons/${icon.name}`);
          const iconSnapshot = await uploadBytes(iconRef, icon);
          updatedCouponData.icon = await getDownloadURL(iconSnapshot.ref);
        } else {
          updatedCouponData.icon = existingVehicle.icon;
        }
  
        // Only update image if a new one is provided
        if (image && typeof image !== 'string') {
          const imageRef = ref(storage, `vehicleImages/${image.name}`);
          const imageSnapshot = await uploadBytes(imageRef, image);
          updatedCouponData.image = await getDownloadURL(imageSnapshot.ref);
        } else {
          updatedCouponData.image = existingVehicle.image;
        }
  
        await updateDoc(couponRef, updatedCouponData);
  
        SetVehicles(prevVehicles =>
          prevVehicles.map(vehicle =>
            vehicle.id === selectedCouponId ? { ...vehicle, ...updatedCouponData } : vehicle
          )
        );
  
        toast.success('Vehicle Updated Successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          theme: 'colored',
        });
      } else {
        // Handle new vehicle creation
        const iconRef = ref(storage, `vehicleIcons/${icon.name}`);
        const imageRef = ref(storage, `vehicleImages/${image.name}`);
  
        const [iconSnapshot, imageSnapshot] = await Promise.all([
          uploadBytes(iconRef, icon),
          uploadBytes(imageRef, image),
        ]);
  
        const [iconUrl, imageUrl] = await Promise.all([
          getDownloadURL(iconSnapshot.ref),
          getDownloadURL(imageSnapshot.ref),
        ]);
  
        const vehiclesRef = collection(db, 'vehicleCollection');
        const docRef = await addDoc(vehiclesRef, { ...updatedCouponData, icon: iconUrl, image: imageUrl });
  
        // Now add docId to the newly created vehicle document
        const newVehicle = {
          id: docRef.id, // adding the Firestore docId to the vehicle data
          ...updatedCouponData,
          icon: iconUrl,
          image: imageUrl,
        };
  
        // Update state with the new vehicle data, including docId
        SetVehicles([{ id: docRef.id, ...newVehicle }, ...vehicles]);
  
        toast.success('Vehicle Added Successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          theme: 'colored',
        });
      }
  
      // Reset form and state
      setName('');
      setPrice('');
      setindex('');
      setVehicleIcon(null);
      setVehicleImage(null);
      setShowvehiclesForm(false);
      setSelectedCouponId(null);
    } catch (error) {
      console.error('Error adding or updating Vehicle:', error);
      toast.error('Failed to save vehicle. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
    }
  };
  
  
  
  const handleEditCoupon = (coupon) => {
    setName(coupon.vehicleName);  // Use the correct property name
    setPrice(coupon.perKmPrice);   // Use the correct property name
    setindex(coupon.index);         // Use the correct property name
    setVehicleImage(coupon.image)
    setVehicleIcon(coupon.icon)
    setSelectedCouponId(coupon.id); // Set the selected coupon ID
    setShowvehiclesForm(true);      // Show the coupon form
  };

  
  const handleIconUpload = (e) => {
    setVehicleIcon(e.target.files[0]);
};

const handleImageUpload = (e) => {
    setVehicleImage(e.target.files[0]);
};


  // Handle delete coupon
  const handleDeleteCoupon = async (id) => {
    try {
      const couponRef = doc(db, 'vehicleCollection', id);
      await deleteDoc(couponRef);
      SetVehicles(vehicles.filter(coupon => coupon.id !== id));
      toast.success('Vehicle Deleted Successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
    } catch (error) {
      console.error('Error deleting Vehicle:', error);
      toast.error('Failed to delete coupon. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
    }
  };


  const handleViewClick = (user) => {
    setSelectedUser(user);
    setIsSidebarOpen(true); 
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false); // Hide the sidebar
  };

  return (
    <div className="">
      <div>
        <div className='flex justify-between'>
          <h1 className="text-lg font-bold sm:text-2xl font-krona">Vehicles</h1>
          <div className='flex gap-4'>
            <div className="relative">
              <input
                type="text"
                placeholder="Search by Name"
                className="py-2 pl-10 pr-4 rounded-md focus:outline-none focus:ring-2 focus:ring-headings"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="absolute left-3 top-2.5">
                <svg
                  className="w-5 h-5 text-gray-900"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 2a9 9 0 100 18 9 9 0 000-18zM21 21l-4.35-4.35"
                  />
                </svg>
              </span>
            </div>
            <button 
              onClick={() => { setShowvehiclesForm(!showvehiclesForm); setSelectedCouponId(null); }}
              className='w-40 py-2 font-semibold rounded-md bg-subHeading text-headings font-krona hover:text-white hover:bg-headings '
            >
              Add vehicles
            </button>
          </div>
        </div>

        {showvehiclesForm && (
  <div className="fixed inset-0 z-40 flex items-center justify-center bg-gray-500 bg-opacity-75">
    <div className="p-6 bg-white rounded-md shadow-lg w-[400px] relative">
      <div className='flex items-center justify-between py-4'>
        <h2 className="text-xl font-bold font-krona">
          {selectedCouponId ? 'Edit Vehicle' : 'Create Vehicle'}
        </h2>
        <button 
          className='px-3 py-1 rounded-full bg-subHeading text-blue'
          onClick={() => setShowvehiclesForm(false)}
        >
          X
        </button>
      </div>

      <div className="flex flex-col space-y-4">
        {/* Vehicle Name */}
        <input 
          type="text" 
          placeholder="Vehicle Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="p-2 py-3 border border-gray-300 rounded-md"
        />

        {/* Per KM Price */}
        <input 
          type="number" 
          placeholder="Per KM Price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          className="p-2 py-3 border border-gray-300 rounded-md"
        />

        {/* Index */}
        <input 
          type="number" 
          placeholder="Index"
          value={index}
          onChange={(e) => setindex(e.target.value)}
          className="p-2 py-3 border border-gray-300 rounded-md"
        />

        {/* Upload Vehicle Icon */}
        <div className="relative">
          <label className="block text-gray-700">Upload Vehicle Icon</label>
          <div className="flex items-center justify-center p-2 border-2 border-gray-300 border-dashed rounded-md">
            <input 
              type="file" 
              accept="image/*"
              className="absolute inset-0 z-10 opacity-0 cursor-pointer"
              onChange={handleIconUpload}
            />
            <div className="flex flex-col items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M4 3a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zM3 5a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V5zm2 1a1 1 0 100 2h10a1 1 0 100-2H5zm0 3a1 1 0 100 2h10a1 1 0 100-2H5z"/>
              </svg>
              <p className="text-sm text-gray-500">Click to upload icon</p>
            </div>
          </div>
        </div>

        {/* Upload Vehicle Image */}
        <div className="relative">
          <label className="block text-gray-700">Upload Vehicle Image</label>
          <div className="flex items-center justify-center p-2 border-2 border-gray-300 border-dashed rounded-md">
            <input 
              type="file" 
              accept="image/*"
              className="absolute inset-0 z-10 opacity-0 cursor-pointer"
              onChange={handleImageUpload}
            />
            <div className="flex flex-col items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-500" viewBox="0 0 24 24" fill="currentColor">
                <path fillRule="evenodd" d="M12 1a4 4 0 00-4 4v1.73a8 8 0 00-6.995 7.867L1 14a8 8 0 008 8h6a8 8 0 008-8c0-4.418-3.582-8-8-8V5a4 4 0 00-4-4zm-1 7.732V5a2 2 0 014 0v3.732a8.055 8.055 0 00-4 0zm-2 .6a6 6 0 1112 0A6 6 0 019 9.332zm1 5.5a1 1 0 100 2h4a1 1 0 100-2h-4z" clipRule="evenodd"/>
              </svg>
              <p className="text-sm text-gray-500">Click to upload image</p>
            </div>
          </div>
        </div>

        {/* Add/Update Coupon Button */}
        <button 
          className="py-3 font-bold rounded-md bg-subHeading font-krona text-headings hover:text-white hover:bg-headings"
          onClick={handleAddCoupon}
        >
          {selectedCouponId ? 'Update Vehicle' : 'Add Vehicle'}
        </button>
      </div>
    </div>
  </div>
)}

        <ToastContainer />
      </div>
    
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <TailSpin height="80" width="80" color="blue" ariaLabel="loading" visible={true} />
        </div>
      ) : (
        <div className="">
        <table className="min-w-full table-auto">
          <thead className="">
            <tr className="font-krona text-custom-gray">
              <td className="px-4 py-4 text-left">Image</td>
              <td className="px-4 py-4 text-left">Vehicle Name</td>
              <td className="px-4 py-4 text-left">Per Km Price</td>
      
              {/* <td className="px-4 py-4 text-left">Index</td> */}
              <td className="px-4 py-4 text-left">Actions</td>
              <td className="px-4 py-4 text-right">Detials</td>
            </tr>
          </thead>
          <tbody>
            {
            vehicles
            .filter((coupon) => coupon.vehicleName.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((coupon) => (
                <tr key={coupon.id} className="border-b-8 bg-white py-1 border-[#F8F8F8]">
                  <td className="px-4 py-4"> {coupon.image ? <img className="w-10 h-10 rounded-full" src={coupon.image || dumyimage} alt=""/> :
                      <img className="w-10 h-10 rounded-full" src={dumyimage} alt=""/> }</td>
                  <td className="px-4 py-4">{coupon.vehicleName}</td>
                  <td className="px-4 py-4">{coupon.perKmPrice}</td>
                  {/* <td className="px-4 py-4">{coupon.index}</td> */}
               
                  <td className="relative px-4 py-4 pr-8 ">
                    {/* Three-dot menu icon */}
                    <button
                      onClick={() => setShowActions(showActions === coupon.id ? null : coupon.id)}
                      className="px-3 py-1 font-bold text-gray-900 border rounded-md hover:bg-blue-600 hover:text-white font-krona"
                    >
                      &#x22EE;
                    </button>
      
                    {/* Dropdown menu */}
                        {showActions === coupon.id && (
                          <div className="absolute right-0 z-10 w-32 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                            <button className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-100" onClick={() => handleEditCoupon(coupon)}>
                              Edit
                            </button>
                            <button className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-100" onClick={() => handleDeleteCoupon(coupon.id)}>
                              Delete
                            </button>
                          </div>
                        )}
                  </td>
                  <td className="py-5 pr-4 text-right">
                    <button
                      className="mr-1 text-gray-900 hover:text-gray-700"
                      onClick={() => handleViewClick(coupon)}
                    >
                      <i className="flex items-center justify-center px-1 py-2 border rounded-md h-7 fas fa-eye"></i>
                    </button>
                  </td>
                
                </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      )}

       {/* Sidebar for displaying user details */}
       <div
        className={`fixed top-0 right-0 w-[400px] h-full bg-white shadow-lg transform transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="h-full overflow-y-auto">
            {selectedUser && (
              <div className="mt-4">
                <h1 className="px-2 py-2 text-2xl font-bold text-gray-900 ">
                  Buyers Detail
                </h1>

                <div className="py-2 space-y-4">
                  <div className="space-y-2">

                <div className="flex flex-col justify-center p-2 pt-0">
  
    
                <img
                    className="object-cover  py-2 pl-2 h-48 w-[98%] rounded-[25px]"
                    src={selectedUser.image || dumyimage}
                    alt="Image Not found"
                  />
                
                        {/* <p className="pt-2 font-semibold">{selectedUser.name}</p>
                        <p className="text-sm text-gray-500">
                          {selectedUser.email}
                        </p> */}
                      </div>
                    </div>
                
                    <p className="pb-3 pl-3 text-gray-900 border-b-2">
                      <strong className="">Country</strong> <br />
                      {selectedUser.country || "N/A"}
                    </p>
                    <p className="pb-3 pl-3 text-gray-900 border-b-2">
                      <strong className="">Phone Number</strong> <br />
                      {selectedUser.phoneNumber || "N/A"}
                    </p>
                  </div>
                </div>
            //   </div>
            )}
          </div>
          <button
            className="p-3 m-4 font-bold text-blue-900 bg-blue-200 rounded-md hover:bg-blue-800 hover:text-white"
            onClick={closeSidebar}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
