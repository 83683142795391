import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { TailSpin } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dumyimage from "../imges/dummy.png";

//

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix the default marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export const Rides = () => {
  // const position = [selectedUser.toLat || 0, selectedUser.toLng || 0];
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [cityData, setCityData] = useState({});
  // Fetch reports based on rideID
  const [reports, setReports] = useState([]); // State to hold reports
  const [showReportsModal, setShowReportsModal] = useState(false);
  // const [showReportsModal, setShowReportsModal] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch Vehicle Type
        const vehiclesSnapshot = await getDocs(
          collection(db, "vehicleCollection")
        );
        const vehiclesMap = {};
        vehiclesSnapshot.docs.forEach((doc) => {
          vehiclesMap[doc.id] = {
            id: doc.id,
            ...doc.data(),
          };
        });
        setCityData(vehiclesMap);

        // Then fetch users
        const usersSnapshot = await getDocs(collection(db, "rideCollection"));
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate?.() || new Date(),
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error loading data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const getCityName = (vehicleType) => {
    if (!vehicleType || !cityData) return "N/A";
    return cityData[vehicleType]?.vehicleName || "N/A";
  };

  const handleViewClick = (user) => {
    setSelectedUser(user);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const filteredUsers = users.filter((user) =>
    `${user.riderName || ""}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const fetchReports = async (rideID) => {
    setLoadingReports(true);
    try {
      const reportsQuery = query(
        collection(db, "reportCollection"),
        where("rideID", "==", rideID)
      );
      const reportsSnapshot = await getDocs(reportsQuery);
      const reportsData = reportsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt
          ? new Date(parseInt(doc.data().createdAt))
          : new Date(),
      }));

      if (reportsData.length === 0) {
        toast.info("No reports found for this ride");
      }

      setReports(reportsData);
      setShowReportsModal(true);
    } catch (error) {
      console.error("Error fetching reports:", error);
      toast.error("Error loading reports");
    } finally {
      setLoadingReports(false);
    }
  };

  const ReportsModal = () => (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="p-6 bg-white rounded-md shadow-lg w-[400px] relative">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold font-krona">Reports</h2>
          <button
            className="px-3 py-1 text-gray-600 bg-blue-200 rounded-full hover:bg-gray-100"
            onClick={() => setShowReportsModal(false)}
          >
            ✕
          </button>
        </div>

        {loadingReports ? (
          <div className="flex items-center justify-center h-32">
            <TailSpin height="40" width="40" color="blue" />
          </div>
        ) : reports.length > 0 ? (
          <div className="space-y-4 max-h-[60vh] overflow-y-auto">
            {reports.map((report) => (
              <div
                key={report.id}
                className="p-4 border rounded-md hover:bg-gray-50"
              >
                <p className="mb-2 text-sm text-gray-600">
                  {report.createdAt.toLocaleString()}
                </p>
                <p className="text-gray-800">
                  <strong>Reason:</strong> {report.reason}
                </p>
                {/* {report.docID && (
                <p className="mt-1 text-sm text-gray-500">
                  Report ID: {report.docID}
                </p>
              )} */}
              </div>
            ))}
          </div>
        ) : (
          <p className="py-8 text-center text-gray-500">
            No reports found for this ride.
          </p>
        )}
      </div>
    </div>
  );

  const [chats, setChats] = useState([]); // State to hold reports
  const [showChatModal, setShowChatModal] = useState(false);
  // const [showReportsModal, setShowReportsModal] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);

  const fetchChats = async (chatID) => {
    setLoadingChats(true);
    try {
      // Query the messages subcollection within the specific group chat document
      const messagesQuery = query(
        collection(db, `groupChatCollection/${chatID}/messages`),
        orderBy("time", "asc")
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      const messagesData = messagesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        time: doc.data().time ? doc.data().time.toDate() : new Date(), // Convert Firestore timestamp to JS Date
      }));

      if (messagesData.length === 0) {
        toast.info("No messages found for this chat.");
      }

      setChats(messagesData);
      setShowChatModal(true);
    } catch (error) {
      console.error("Error fetching messages:", error);
      toast.error("Error loading messages.");
    } finally {
      setLoadingChats(false);
    }
  };

  const ChatsModal = () => (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="p-6 bg-white rounded-md shadow-lg w-[400px] relative">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold">Chats</h2>
          <button
            className="px-3 py-1 text-gray-600 bg-blue-200 rounded-full hover:bg-gray-100"
            onClick={() => setShowChatModal(false)}
          >
            ✕
          </button>
        </div>

        {loadingChats ? (
          <div className="flex items-center justify-center h-32">
            <TailSpin height="40" width="40" color="blue" />
          </div>
        ) : chats.length > 0 ? (
          <div className="space-y-4 max-h-[60vh] overflow-y-auto">
            {chats.map((chat) => (
              <div
                key={chat.id}
                className="p-4 pl-2 border rounded-md hover:bg-gray-50"
              >
                <p className="pl-1 mb-2 text-sm text-gray-600">
                  {chat.time.toLocaleString()}
                </p>
                <div className="flex items-center justify-start space-x-2">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={chat.giveByImage || dumyimage}
                    alt=""
                  />
                  <div>
                    <p className="font-bold">{chat.name || "N/A"} </p>
                  </div>
                </div>

                {/* <p className="font-bold text-gray-800">{chat.name}</p> */}
                <p className="pl-2 text-gray-800">{chat.message}</p>
                {/* {chat.fileUrl && (
                <a href={chat.fileUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                  {chat.fileName || "Attachment"}
                </a>
              )} */}
              </div>
            ))}
          </div>
        ) : (
          <p className="py-8 text-center text-gray-500">
            No Chats found for this chat.
          </p>
        )}
      </div>
    </div>
  );
  // Fix the default marker icon issue

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-lg font-bold sm:text-2xl font-krona">Rides</h1>
        <div className="relative">
          <input
            type="text"
            placeholder="Search by username"
            className="py-2 pl-10 pr-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="absolute left-3 top-2.5">
            <svg
              className="w-5 h-5 text-gray-900"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 2a9 9 0 100 18 9 9 0 000-18zM21 21l-4.35-4.35"
              />
            </svg>
          </span>
        </div>
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <TailSpin
            height="80"
            width="80"
            color="blue"
            ariaLabel="loading"
            visible={true}
          />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full rounded-lg shadow-lg table-auto">
            <thead>
              <tr className="text-lg text-left text-custom-gray">
                <td className="px-4 py-2">Profile</td>
                <td className="px-4 py-2">Phone</td>
                <td className="px-4 py-2">Active</td>
                <td className="px-4 py-2">Completed</td>
                <td className="px-4 py-2">Reports</td>
                <td className="px-4 py-2">Chats</td>

                <td className="px-4 py-2 text-center">Details</td>
              </tr>
            </thead>

            <tbody className="bg-white">
              {filteredUsers
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((user) => (
                  <tr
                    key={user.id}
                    className="border-b-8 border-[#F8F8F8] py-2"
                  >
                    <td className="px-4 py-5">
                      <div className="flex items-center justify-start space-x-2">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={user.riderImage || dumyimage}
                          alt=""
                        />
                        <div>
                          <p className="font-semibold">
                            {user.riderName || "N/A"}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-5">
                      {user.riderPhoneNumber || "N/A"}
                    </td>
                    <td className="px-4 py-5">
                      {user.isActive === true ? (
                        <span className="px-3 py-2 text-sm font-semibold text-green-700 bg-green-100 rounded-md ">
                          Completed
                        </span>
                      ) : (
                        <span className="px-3 py-2 text-sm font-semibold text-yellow-700 bg-yellow-100 rounded-lg ">
                          Pending
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-5">
                      {user.isCancelled === false &&
                      user.isCompleted === true ? (
                        <span className="px-3 py-2 text-sm font-semibold text-green-700 bg-green-100 rounded-md ">
                          Completed
                        </span>
                      ) : (
                        <span className="px-3 py-2 text-sm font-semibold text-yellow-700 bg-yellow-100 rounded-lg ">
                          Pending
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-5">
                      <button
                        className="mr-1 text-gray-900 hover:text-gray-700"
                        onClick={() => fetchReports(user.id)} // Fetch reports for this ride
                      >
                        <i className="flex items-center justify-center px-1 py-2 border rounded-md h-7 fas fa-file-alt"></i>
                      </button>
                    </td>
                    <td className="px-4 py-5">
                      <button
                        className="mr-1 text-gray-900 hover:text-gray-700"
                        onClick={() => fetchChats(user.id)} // Fetch reports for this ride
                      >
                        <i className="flex items-center justify-center px-1 py-2 border rounded-md h-7 fas fa-message"></i>
                      </button>
                    </td>
                    <td className="py-5 text-center">
                      <button
                        className="mr-1 text-gray-900 hover:text-gray-700"
                        onClick={() => handleViewClick(user)}
                      >
                        <i className="flex items-center justify-center px-1 py-2 border rounded-md h-7 fas fa-eye"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <ToastContainer />

      {showReportsModal && <ReportsModal />}
      {showChatModal && <ChatsModal />}
      <div
        className={`fixed top-0 right-0 w-[400px] h-full bg-white shadow-lg transform transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="h-full overflow-y-auto">
            {selectedUser && (
              <div className="mt-4">
                <h1 className="px-2 py-2 text-2xl font-bold text-gray-900">
                  Rides Detail
                </h1>

                <div className="py-2 space-y-4">
                  <div className="space-y-2">
                    <div className="flex flex-col justify-center p-2 pt-0">
                      <img
                        className="object-cover py-2 pl-2 h-48 w-[98%] rounded-[25px]"
                        src={selectedUser.image || dumyimage}
                        alt="Image Not found"
                      />
                    </div>
                  </div>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">Vehicle Type</strong> <br />
                    {getCityName(selectedUser.vehicleType)}
                    <br />
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">To</strong> <br />
                    {selectedUser.to || "N/A"} <br />
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">From</strong> <br />
                    {selectedUser.from || "N/A"} <br />
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">Distance</strong> <br />
                    {selectedUser.distance + " Km" || "N/A"} <br />
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">Amount</strong> <br />
                    {selectedUser.amount + " Pkr" || "N/A"} <br />
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">DAte & Time</strong> <br />
                    <div className="flex gap-2">
                      <p>
                        {new Date(selectedUser.createdAt).toLocaleDateString()}
                      </p>
                      <span className="text-custom-gray">|</span>
                      <p className="text-custom-gray">
                        {new Date(selectedUser.createdAt).toLocaleTimeString()}
                      </p>
                    </div>
                  </p>
                  {/*               
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">Travel Time</strong>  <br/>
                    {selectedUser.travelTime || "N/A"} <br/>        
                  </p> */}
                  {/* <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">To Lat</strong>  <br/>
                    {selectedUser.toLat || "N/A"} <br/>        
                  </p> <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">To Lng</strong>  <br/>
                    {selectedUser.toLng || "N/A"} <br/>        
                  </p> */}
                  {/* <MapContainer center={selectedUser ? [selectedUser.toLat || 0, selectedUser.toLng || 0] : [51.505, -0.09]} zoom={13} style={{ height: '100%', width: '100%' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                />
                <Marker position={[selectedUser.toLat || 0, selectedUser.toLng || 0]}>
                  <Popup>{selectedUser.riderName}</Popup>
                </Marker>
              </MapContainer> */}

                  {/* <div className="h-[300px] w-full p-4">
                  {selectedUser.toLat && selectedUser.toLng && (
                    <MapContainer
                      center={[selectedUser.toLat, selectedUser.toLng]}
                      zoom={13}
                      style={{ height: '100%', width: '100%' }}
                      whenCreated={setMap}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <Marker position={[selectedUser.toLat, selectedUser.toLng]}>
                        <Popup>
                          <div>
                            <strong>{selectedUser.riderName || 'Location'}</strong><br/>
                            Lat: {selectedUser.toLat}<br/>
                            Lng: {selectedUser.toLng}
                          </div>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  )}
                </div> */}

                  {/* <div className="h-[300px] w-full p-4">
                  {selectedUser.fromLat && selectedUser.fromLng && (
                    <MapContainer
                      center={[selectedUser.fromLat, selectedUser.fromLng]}
                      zoom={13}
                      style={{ height: '100%', width: '100%' }}
                      whenCreated={setMap}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <Marker position={[selectedUser.fromLat, selectedUser.fromLng]}>
                        <Popup>
                          <div>
                            <strong>{selectedUser.riderName || 'Location'}</strong><br/>
                            Lat: {selectedUser.fromLat}<br/>
                            Lng: {selectedUser.fromLng}
                          </div>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  )}
                </div> */}

                  {/* <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">From Lat</strong>  <br/>
                    {selectedUser.fromLat || "N/A"} <br/>        
                  </p> <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">From Lng</strong>  <br/>
                    {selectedUser.fromLng || "N/A"} <br/>        
                  </p> */}
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="text-xl">User Date </strong> <br />
                    {/* {getCityName(selectedUser.cityID)} */}
                    <strong className="">User Name</strong> <br />
                    {selectedUser.userName || "N/A"} <br />
                    <strong className="">User Phone Number</strong> <br />
                    {selectedUser.userPhoneNumber || "N/A"}
                  </p>
                </div>
              </div>
            )}
          </div>
          <button
            className="p-3 m-4 font-bold text-blue-900 bg-blue-200 rounded-md hover:bg-blue-800 hover:text-white"
            onClick={closeSidebar}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};
