import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify'; 
import 'react-quill/dist/quill.snow.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";

export const TermsConditions = () => {
    const [policy, setPolicy] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentDocId, setCurrentDocId] = useState(null);

    useEffect(() => {
        const fetchPolicy = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'termsAndConditionCollection'));
                const policies = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (policies.length > 0) {
                    setPolicy(policies[0].description);
                    setCurrentDocId(policies[0].id);
                }
            } catch (error) {
                console.error('Error fetching policy:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPolicy();
    }, []);

    const handleSave = async () => {
        if (currentDocId) {
            try {
                const docRef = doc(db, 'termsAndConditionCollection', currentDocId);
                await updateDoc(docRef, { description: policy });
                toast.success('Policy updated successfully');
                setEditMode(false);
            } catch (error) {
                // toast.error('Error updating policy');
                console.error('Error updating policy:', error);
            }
        }
    };

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    };

    return (
        <div className="p-4 privacy-policy-container">
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-lg font-bold sm:text-2xl">Terms & Condition</h1>
                {!editMode && (
                    <button
                        onClick={() => setEditMode(true)}
                        className="px-3 py-1 bg-green-200 border rounded-md text-headings hover:bg-blue-50"
                    >
                      <i className='fas fa-edit '></i>
                      
                    </button>
                )}
            </div>
            {loading ? (
                <div className="flex items-center justify-center h-64">
                    <TailSpin height="80" width="80" color="blue" ariaLabel="loading" visible={true} />
                </div>
            ) : (
                <div>
                    {editMode ? (
                        <div className="p-4 bg-white rounded-lg shadow-md quill-container">
                            <ReactQuill 
                                value={policy} 
                                onChange={setPolicy}
                                theme="snow"
                            />
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={() => setEditMode(false)}
                                    className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="p-4 prose bg-white rounded-lg policy-text max-w-none">
                            <div dangerouslySetInnerHTML={createMarkup(policy)} />
                        </div>
                    )}
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

// export default PrivacyPolicy;