import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { TailSpin } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import dumyimage from '../imges/dummy.png';

export const ContactUs = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [cityData, setCityData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch all cities first
                // const citiesSnapshot = await getDocs(collection(db, "cityCollection"));
                // const citiesMap = {};
                // citiesSnapshot.docs.forEach((doc) => {
                //     // Use docID as the key in our map
                //     citiesMap[doc.id] = {
                //         id: doc.id,
                //         ...doc.data()
                //     };
                // });
                // setCityData(citiesMap);

                // Then fetch users
                const usersSnapshot = await getDocs(collection(db, "contactUsCollection"));
                const usersData = usersSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    createdAt: doc.data().createdAt.toDate(),

                }));
                setUsers(usersData);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error loading data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // const getCityName = (cityID) => {
    //     if (!cityID || !cityData) return 'N/A';
    //     return cityData[cityID]?.name || 'N/A';
    // };

    const handleViewClick = (user) => {
        setSelectedUser(user);
        setIsSidebarOpen(true);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const filteredUsers = users.filter((user) =>
        `${user.name || ''}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-lg font-bold sm:text-2xl font-krona">Queries</h1>
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search by username"
                        className="py-2 pl-10 pr-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="absolute left-3 top-2.5">
                        <svg
                            className="w-5 h-5 text-gray-900"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M11 2a9 9 0 100 18 9 9 0 000-18zM21 21l-4.35-4.35"
                            />
                        </svg>
                    </span>
                </div>
            </div>

            {loading ? (
                <div className="flex items-center justify-center h-64">
                    <TailSpin
                        height="80"
                        width="80"
                        color="blue"
                        ariaLabel="loading"
                        visible={true}
                    />
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full rounded-lg shadow-lg table-auto">
                        <thead>
                            <tr className="text-lg text-left text-custom-gray">
                                <td className="px-4 py-2">#ID</td>
                                <td className="px-4 py-2">Name</td>
                                <td className="px-4 py-2">Phone</td>
                                <td className="px-4 py-2">Email</td>
                                <td className="px-4 py-2">Subject</td>
                                <td className="px-4 py-2">Description</td>
                                <td className="px-4 py-2">Date & Time</td>
                                {/* <td className="px-4 py-2 text-center">Detials</td> */}
                            </tr>
                        </thead>

                        <tbody className="bg-white">
                            {filteredUsers
                                .sort((a, b) => b.createdAt - a.createdAt)
                                .map((user) => (
                                    <tr key={user.id} className="border-b-8 border-[#F8F8F8] py-2">
                                                        <td className="px-4 py-5">
                      <p className="font-semibold uppercase">#{user.id.slice(0, 8)}</p>
                    </td>
                                        <td className="px-4 py-5">
                                            <div className="flex items-center justify-start space-x-2">
                                                {/* <img
                                                    className="w-10 h-10 rounded-full"
                                                    src={user.image || dumyimage}
                                                    alt=""
                                                /> */}
                                                <div>
                                                    <p className="font-semibold">{user.name || 'N/A'}</p>
                                                    {/* <p className="text-sm text-gray-500">{user.email || 'N/A'}</p> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-4 py-5">{user.phoneNumber || 'N/A'}</td>
                                        <td className="px-4 py-5">{user.email || 'N/A'}</td>
                                        <td className="px-4 py-5">{user.subject || 'N/A'}</td>
                                        <td className="px-4 py-5">
                                        {user.description || 'N/A'}
                                        </td>
                                        <td className="px-4 py-6">
                                            <div className="flex gap-2">
                                                <p>{new Date(user.createdAt).toLocaleDateString()}</p>
                                                <span className="text-custom-gray">|</span>
                                                <p className="text-custom-gray">
                                                    {new Date(user.createdAt).toLocaleTimeString()}
                                                </p>
                                            </div>
                                        </td>
                                        {/* <td className="py-5 text-center">
                                            <button
                                                className="mr-1 text-gray-900 hover:text-gray-700"
                                                onClick={() => handleViewClick(user)}
                                            >
                                                <i className="flex items-center justify-center px-1 py-2 border rounded-md h-7 fas fa-eye"></i>
                                            </button>
                                        </td> */}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            )}
            <ToastContainer />

            {/* Sidebar for displaying user details */}
            {/* <div
                className={`fixed top-0 right-0 w-[400px] h-full bg-white shadow-lg transform transition-transform duration-300 ${
                    isSidebarOpen ? "translate-x-0" : "translate-x-full"
                }`}
            >
                <div className="flex flex-col justify-between h-full">
                    <div className="h-full overflow-y-auto">
                        {selectedUser && (
                            <div className="mt-4">
                                <h1 className="px-2 py-2 text-2xl font-bold text-gray-900">
                                    Buyers Detail
                                </h1>

                                <div className="py-2 space-y-4">
                                    <div className="space-y-2">
                                        <div className="flex flex-col justify-center p-2 pt-0">
                                            <img
                                                className="object-cover py-2 pl-2 h-48 w-[98%] rounded-[25px]"
                                                src={selectedUser.image || dumyimage}
                                                alt="Image Not found"
                                            />
                                        </div>
                                    </div>

                                    <p className="pb-3 pl-3 text-gray-900 border-b-2">
                                        <strong className="">City</strong> <br />
                                        {getCityName(selectedUser.cityID)}
                                    </p>
                                    <p className="pb-3 pl-3 text-gray-900 border-b-2">
                                        <strong className="">Phone Number</strong> <br />
                                        {selectedUser.phoneNumber || "N/A"}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <button
                        className="p-3 m-4 font-bold text-blue-900 bg-blue-200 rounded-md hover:bg-blue-800 hover:text-white"
                        onClick={closeSidebar}
                    >
                        Close
                    </button>
                </div>
            </div> */}
        </>
    );
};