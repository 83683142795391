import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; 
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
  apiKey: "AIzaSyDxaOr23IRzUvEr2bqTZ2oclfk4daiFAmY",
  authDomain: "riding-app-973ca.firebaseapp.com",
  projectId: "riding-app-973ca",
  storageBucket: "riding-app-973ca.appspot.com",
  messagingSenderId: "817643472922",
  appId: "1:817643472922:web:2eb887d31ebe56f26ee9a3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
// Initialize Firebase Storage for handling image uploads
export const storage = getStorage(app); 
export { app };
  // Initialize Firebase Authentication
export const auth = getAuth(app);
