import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  query, where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { TailSpin } from "react-loader-spinner"; // Spinner for loading state
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dumyimage from "../imges/dummy.png";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
export const Riders = () => {
  const [vehicles, SetVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showvehiclesForm, setShowvehiclesForm] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  // const [index, setindex] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCouponId, setSelectedCouponId] = useState(null); // For editing
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showActions, setShowActions] = useState(null); // Controls which dropdown is shown
  const [selectedUser, setSelectedUser] = useState(null); // State to track selected user
  const [cnicFront, setVehicleIcon] = useState(null);
  const [cnicBack, setVehicleImage] = useState(null);
  // Fetch vehicles from Firestore
  useEffect(() => {
    const fetchvehicles = async () => {
      setLoading(true); // Show loading spinner while fetching data
      try {
        const querySnapshot = await getDocs(collection(db, "riderCollection"));
        const vehiclesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // date: doc.data().date.toDate(),
        }));
        SetVehicles(vehiclesData); // Store the fetched vehicles in state
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      } finally {
        setLoading(false); // Hide spinner after fetching is done
      }
    };

    fetchvehicles();
  }, []);

  const handleAddCoupon = async () => {
    // Validation checks for required fields
    if (!name && !selectedCouponId) {
      toast.error('Name Cannot be Empty.....', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    } else if (!contact && !selectedCouponId) {
      toast.error('Price Cannot be Empty.....', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    }  else if (!cnicFront && !selectedCouponId) {
      toast.error('Please Upload Vehicle Icon', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    } else if (!cnicBack && !selectedCouponId) {
      toast.error('Please Upload Vehicle Image', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
      return;
    }

    try {
      // Prepare data for the coupon update
      const updatedCouponData = {};
      if (name) updatedCouponData.name = name; // Only update if a new name is provided
      if (contact) updatedCouponData.phoneNumber = contact; // Only update if a new price is provided
      // if (index) updatedCouponData.index = parseFloat(index); // Only update if a new index is provided

      if (selectedCouponId) {
        const couponRef = doc(db, 'riderCollection', selectedCouponId);

        // Handle icon upload if provided
        if (cnicFront) {
          const iconRef = ref(storage, `vehicleIcons/${cnicFront.name}`);
          const iconSnapshot = await uploadBytes(iconRef, cnicFront);
          const iconUrl = await getDownloadURL(iconSnapshot.ref);
          updatedCouponData.cnicFront = iconUrl; // Store the new icon URL
        }

        // Handle image upload if provided
        if (cnicBack) {
          const imageRef = ref(storage, `vehicleImages/${cnicBack.name}`);
          const imageSnapshot = await uploadBytes(imageRef, cnicBack);
          const imageUrl = await getDownloadURL(imageSnapshot.ref);
          updatedCouponData.cnicBack = imageUrl; // Store the new image URL
        }

        // Update existing coupon only with fields that are provided
        await updateDoc(couponRef, updatedCouponData);
        SetVehicles((prevvehicles) =>
          prevvehicles.map((coupon) =>
            coupon.id === selectedCouponId ? { ...coupon, ...updatedCouponData } : coupon
          )
        );

        toast.success('Vehicle Updated Successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          theme: 'colored',
        });
      } else {
        // If no selectedCouponId, we are adding a new coupon
        const iconRef = ref(storage, `vehicleIcons/${cnicFront.name}`);
        const imageRef = ref(storage, `vehicleImages/${cnicBack.name}`);

        const [iconSnapshot, imageSnapshot] = await Promise.all([
          uploadBytes(iconRef, cnicFront),
          uploadBytes(imageRef, cnicBack),
        ]);

        const iconUrl = await getDownloadURL(iconSnapshot.ref);
        const imageUrl = await getDownloadURL(imageSnapshot.ref);

        const newCoupon = {
          name: name,
          phoneNumber: contact,
          // index: parseFloat(index),
          cnicFront: iconUrl,
          cnicBack: imageUrl,
        };

        const vehiclesRef = collection(db, 'riderCollection');
        const docRef = await addDoc(vehiclesRef, newCoupon);
        SetVehicles([{ id: docRef.id, ...newCoupon }, ...vehicles]);

        toast.success('Vehicle Added Successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          theme: 'colored',
        });
      }

      // Reset form and state
      setName('');
      setContact('');
      // setindex('');
      setVehicleIcon(null);
      setVehicleImage(null);
      setShowvehiclesForm(false);
      setSelectedCouponId(null);
    } catch (error) {
      console.error('Error adding or updating Vehicle:', error);
      toast.error('Failed to save coupon. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });
    }
  };

  // Function to set the selected coupon for editing
  const handleEditCoupon = (coupon) => {
    setName(coupon.name); // Use the correct property name
    setContact(coupon.phoneNumber); // Use the correct property name
    // setindex(coupon.index); // Use the correct property name
    setVehicleImage(coupon.cnicBack);
    setVehicleIcon(coupon.cnicFront);
    setSelectedCouponId(coupon.id); // Set the selected coupon ID
    setShowvehiclesForm(true); // Show the coupon form
  };

  const handleIconUpload = (e) => {
    setVehicleIcon(e.target.files[0]);
  };

  const handleImageUpload = (e) => {
    setVehicleImage(e.target.files[0]);
  };


  const handleViewClick = (user) => {
    setSelectedUser(user);
    setIsSidebarOpen(true);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false); // Hide the sidebar
  };
  // Add these functions after the existing useState declarations

  const toggleApproval = async (rider) => {
    try {
      const riderRef = doc(db, "riderCollection", rider.id);
      const newStatus = !rider.isApproved;

      // Update in Firebase
      await updateDoc(riderRef, {isApproved: newStatus,});

      // Update local state
      SetVehicles((prevRiders) =>
        prevRiders.map((r) =>
          r.id === rider.id ? { ...r, isApproved: newStatus } : r
        )
      );

      toast.success(
        `Rider ${newStatus ? "approved" : "unapproved"} successfully!`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "colored",
        }
      );
    } catch (error) {
      console.error("Error updating approval status:", error);
      toast.error("Failed to update approval status. Please try again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "colored",
      });
    }
  };

  const toggleBlocked = async (rider) => {
    try {
      const riderRef = doc(db, 'riderCollection', rider.id);
      const newStatus = !rider.isBlocked;
      // Update in Firebase
      await updateDoc(riderRef, {
        isBlocked: newStatus
      })
      // Update local state
      SetVehicles(prevRiders =>
        prevRiders.map(r =>
          r.id === rider.id
            ? { ...r, isBlocked: newStatus }
            : r
        )
      )
      toast.success(`Rider ${newStatus ? 'blocked' : 'unblocked'} successfully!`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
      });

    } catch (error) {
      toast.error('Failed to update blocked status. Please try again.');
    }
  };
 






  // Fetch reports based on rideID
const [reports, setReports] = useState([]);  // State to hold reports
const [showReportsModal, setShowReportsModal] = useState(false);
// const [showReportsModal, setShowReportsModal] = useState(false);
const [loadingReports, setLoadingReports] = useState(false); 

const fetchReviews = async (giveToID) => {
  setLoadingReports(true);
  try {
    const reportsQuery = query(
      collection(db, "ratingReviewCollection"),
      where("giveToID", "==", giveToID)
    );
    const reportsSnapshot = await getDocs(reportsQuery);
    const reportsData = reportsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt 
        ? new Date(parseInt(doc.data().createdAt))
        : new Date()
    }));
    
    if (reportsData.length === 0) {
      toast.info("No Reviews found for this ride");
    }
    
    setReports(reportsData);
    setShowReportsModal(true);
  } catch (error) {
    console.error("Error fetching Reviews:", error);
    toast.error("Error loading reports");
  } finally {
    setLoadingReports(false);
  }
};
  const ReportsModal = () => (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="p-6 bg-white rounded-md shadow-lg w-[400px] relative">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold font-krona">Reviews</h2>
          <button
            className="px-3 py-1 font-semibold text-gray-600 rounded-full bg-subHeading hover:bg-gray-100"
            onClick={() => setShowReportsModal(false)}
          >
            ✕
          </button>
        </div>
        
        {loadingReports ? (
          <div className="flex items-center justify-center h-32">
            <TailSpin height="40" width="40" color="blue" />
          </div>
        ) : reports.length > 0 ? (
          <div className="space-y-4 max-h-[60vh] overflow-y-auto">
            {reports.map((report) => (
              <div key={report.id} className="p-4 border rounded-md hover:bg-gray-50">
                {/* <p className="mb-2 text-sm text-gray-600">
                  {report.createdAt.toLocaleString()}
                </p>
                <p className="text-gray-800">
                  <strong>Reviews:</strong> {report.rating}
                  <strong>Reason:</strong> {report.giveByName}
                </p> */}
                <div className="flex items-center justify-start space-x-2">
                                                <img
                                                    className="w-10 h-10 rounded-full"
                                                    src={report.giveByImage || dumyimage}
                                                    alt=""
                                                />
                                                <div>
                                                    <p className="pt-1 font-bold">{report.giveByName || 'N/A'} </p>
                                                  
                                                    {Array.from({ length: report.rating }).map((_, index) => (
      <span key={index} className="text-yellow-400 star"> <i className="fas fa-star"></i></span>
    ))}
                                                </div>
                                            </div>
                                              <div className="flex gap-2 py-2"><strong>Review :</strong>  <p>{report.review || 'N/A'}</p></div>
       
              </div>
            ))}
          </div>
        ) : (
          <p className="py-8 text-center text-gray-500">No Reviews found for this ride.</p>
        )}
      </div>
    </div>
  );






















  return (
    <div className="">
      <div>
        <div className="flex justify-between">
          <h1 className="text-lg font-bold sm:text-2xl font-krona">Riders</h1>
          <div className="flex gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search by Name"
                className="py-2 pl-10 pr-4 rounded-md focus:outline-none focus:ring-2 focus:ring-headings"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="absolute left-3 top-2.5">
                <svg
                  className="w-5 h-5 text-gray-900"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 2a9 9 0 100 18 9 9 0 000-18zM21 21l-4.35-4.35"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        {showvehiclesForm && (
          <div className="fixed inset-0 z-40 flex items-center justify-center bg-gray-500 bg-opacity-75">
            <div className="p-6 bg-white rounded-md shadow-lg w-[400px] relative">
              <div className="flex items-center justify-between py-4">
                <h2 className="text-xl font-bold font-krona">
                  {selectedCouponId ? "Edit Vehicle" : "Create Vehicle"}
                </h2>
                <button
                  className="px-3 py-1 rounded-full bg-subHeading text-blue"
                  onClick={() => setShowvehiclesForm(false)}
                >
                  X
                </button>
              </div>

              <div className="flex flex-col space-y-4">
                {/* Vehicle Name */}
                <input
                  type="text"
                  placeholder="Vehicle Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="p-2 py-3 border border-gray-300 rounded-md"
                />

                {/* Per KM Price */}
                <input
                  type="number"
                  placeholder="Per KM Price"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  className="p-2 py-3 border border-gray-300 rounded-md"
                />

                {/* Index */}
                {/* <input
                  type="number"
                  placeholder="Index"
                  value={index}
                  onChange={(e) => setindex(e.target.value)}
                  className="p-2 py-3 border border-gray-300 rounded-md"
                /> */}

                {/* Upload Vehicle Icon */}
                <div className="relative">
                  <label className="block text-gray-700">
                    Upload Vehicle Icon
                  </label>
                  <div className="flex items-center justify-center p-2 border-2 border-gray-300 border-dashed rounded-md">
                    <input
                      type="file"
                      accept="image/*"
                      className="absolute inset-0 z-10 opacity-0 cursor-pointer"
                      onChange={handleIconUpload}
                    />
                    <div className="flex flex-col items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-8 text-gray-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M4 3a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zM3 5a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V5zm2 1a1 1 0 100 2h10a1 1 0 100-2H5zm0 3a1 1 0 100 2h10a1 1 0 100-2H5z" />
                      </svg>
                      <p className="text-sm text-gray-500">
                        Click to upload icon
                      </p>
                    </div>
                  </div>
                </div>

                {/* Upload Vehicle Image */}
                <div className="relative">
                  <label className="block text-gray-700">
                    Upload Vehicle Image
                  </label>
                  <div className="flex items-center justify-center p-2 border-2 border-gray-300 border-dashed rounded-md">
                    <input
                      type="file"
                      accept="image/*"
                      className="absolute inset-0 z-10 opacity-0 cursor-pointer"
                      onChange={handleImageUpload}
                    />
                    <div className="flex flex-col items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-8 text-gray-500"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 1a4 4 0 00-4 4v1.73a8 8 0 00-6.995 7.867L1 14a8 8 0 008 8h6a8 8 0 008-8c0-4.418-3.582-8-8-8V5a4 4 0 00-4-4zm-1 7.732V5a2 2 0 014 0v3.732a8.055 8.055 0 00-4 0zm-2 .6a6 6 0 1112 0A6 6 0 019 9.332zm1 5.5a1 1 0 100 2h4a1 1 0 100-2h-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p className="text-sm text-gray-500">
                        Click to upload image
                      </p>
                    </div>
                  </div>
                </div>

                {/* Add/Update Coupon Button */}
                <button 
          className="py-3 font-bold rounded-md bg-subHeading font-krona text-headings hover:text-white hover:bg-headings"
          onClick={handleAddCoupon}
        >
          {selectedCouponId ? 'Update Vehicle' : 'Add Vehicle'}
        </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <TailSpin
            height="80"
            width="80"
            color="blue"
            ariaLabel="loading"
            visible={true}
          />
        </div>
      ) : (
        <div className="">
          <table className="min-w-full table-auto">
            <thead className="">
              <tr className="font-krona text-custom-gray">
                <td className="px-4 py-4 text-left">Image</td>
                <td className="px-4 py-4 text-left">Name</td>
                <td className="px-4 py-4 text-left">Email</td>
                {/* <td className="px-4 py-4 text-left">Date</td> */}
                <td className="px-4 py-4 text-left">Contact</td>
                <td className="px-4 py-4 text-left">Is Approved</td>
                <td className="px-4 py-4 text-left">Status</td>
                <td className="px-4 py-4 text-left">Reviews</td>
                <td className="px-4 py-4 text-right">Actions</td>
                <td className="px-4 py-4 text-right">Detials</td>
              </tr>
            </thead>
            <tbody>
              {vehicles
             
                .filter((rider) => rider.name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((rider) => (
                  <tr
                    key={rider.id}
                    className="border-b-8 bg-white py-1 border-[#F8F8F8]"
                  >
                    <td className="px-4 py-4">
                      {" "}
                      {rider.image ? (
                        <img
                          className="w-10 h-10 rounded-full"
                          src={rider.image || dumyimage}
                          alt=""
                        />
                      ) : (
                        <img
                          className="w-10 h-10 rounded-full"
                          src={dumyimage}
                          alt=""
                        />
                      )}
                    </td>
                    <td className="px-4 py-4">{rider.name}</td>
                    <td className="px-4 py-4">{rider.email}</td>
                    <td className="px-4 py-4">{rider.phoneNumber}</td>
                    <td className="px-4 py-4">
                      <label className="relative inline-flex items-start cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={rider.isApproved}
                          onChange={() => toggleApproval(rider)}
                        />
                        <div
                          className="w-10 h-5 bg-gray-200 rounded-full relative peer dark:bg-gray-700 
                       peer-checked:bg-green-500 peer-checked:after:translate-x-full after:transition-all 
                       after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white 
                       after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4"
                        ></div>
                      </label>
                    </td>
                    <td className="px-4 py-4">
                      <label className="relative inline-flex items-start cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={rider.isBlocked}
                          onChange={() => toggleBlocked(rider)}
                        />
                        <div
                          className="w-10 h-5 bg-gray-200 rounded-full relative peer dark:bg-gray-700 
                       peer-checked:bg-green-500 peer-checked:after:translate-x-full after:transition-all 
                       after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white 
                       after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4"
                        ></div>
                      </label>
                    </td>
                    <td className="py-5 text-center">
                      <button
                        className="mr-1 text-gray-900 hover:text-gray-700"
                        onClick={() => fetchReviews(rider.id)}
                      >
                        <i className="flex items-center justify-center px-1 py-2 border rounded-md h-7 fas fa-star"></i>
                      </button>
                    </td>
                    <td className="relative px-4 py-4 pr-8 text-right">
                      {/* Three-dot menu icon */}
                      <button
                        onClick={() =>
                          setShowActions(
                            showActions === rider.id ? null : rider.id
                          )
                        }
                        className="px-3 py-1 font-bold text-gray-900 border rounded-md hover:bg-blue-600 hover:text-white font-krona"
                      >
                        &#x22EE;
                      </button>

                      {/* Dropdown menu */}
                      {showActions === rider.id && (
                        <div className="absolute right-0 z-10 w-32 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                          <button
                            className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-100"
                            onClick={() => handleEditCoupon(rider)}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="py-5 pr-4 text-right">
                      <button
                        className="mr-1 text-gray-900 hover:text-gray-700"
                        onClick={() => handleViewClick(rider)}
                      >
                        <i className="flex items-center justify-center px-1 py-2 border rounded-md h-7 fas fa-eye"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {showReportsModal && <ReportsModal />}
        </div>
      )}
      
      {/* Sidebar for displaying user details */}
      <div
        className={`fixed top-0 right-0 w-[400px] h-full bg-white shadow-lg transform transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="h-full overflow-y-auto">
            {selectedUser && (
              <div className="mt-4">
                <h1 className="px-2 py-2 text-2xl font-bold text-gray-900 ">
                  Buyers Detail
                </h1>

                <div className="py-2 space-y-4">
                  <div className="space-y-2">
                    <div className="flex flex-col justify-center p-2 pt-0">
                      <img
                        className="object-cover  py-2 pl-2 h-48 w-[98%] rounded-[25px]"
                        src={selectedUser.image || dumyimage}
                        alt="Image Not found"
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                  <p className="pb-3 text-gray-900 border-b-2">
                    <strong className="pl-3">CNIC Front & Back Image</strong> <br />
                    <div className="flex justify-center p-2 pt-0">
                      
                      <img
                        className="object-cover  py-2 pl-2 h-48 w-[50%] rounded-[25px]"
                        src={selectedUser.cnicFront || dumyimage}
                        alt="Image Not found"
                      />
                       <img
                        className="object-cover  py-2 pl-2 h-48 w-[50%] rounded-[25px]"
                        src={selectedUser.cnicBack || dumyimage}
                        alt="Image Not found"
                      />
                    </div>
                  </p>
                
                  </div>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">Name</strong> <br />
                    {selectedUser.name || "N/A"}
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">Phone Number</strong> <br />
                    {selectedUser.phoneNumber || "N/A"}
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">Email</strong> <br />
                    {selectedUser.email || "N/A"}
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">CNIC</strong> <br />
                    {selectedUser.cnic || "N/A"}
                  </p>
                  <p className="pb-3 pl-3 text-gray-900 border-b-2">
                    <strong className="">City</strong> <br />
                    {selectedUser.cityName || "N/A"}
                  </p>
                </div>
              </div>
              //   </div>
            )}
          </div>
          <button
            className="p-3 m-4 font-bold text-blue-900 bg-blue-200 rounded-md hover:bg-blue-800 hover:text-white"
            onClick={closeSidebar}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
